import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Image from "react-bootstrap/Image";
import Button from "@material-ui/core/Button";
import { Link } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputBase from "@material-ui/core/InputBase";
import ArrowDrop from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import FormControl from "@material-ui/core/FormControl";
import Rating from "@material-ui/lab/Rating";
import Pagination from "@material-ui/lab/Pagination";
import AsyncSelect from "react-select/async";

import imgAp from "../../assets/img/imgLogin.jpg";
import "react-select-search/style.css";

import Footer from "../../components/footer";
import Header from "../../components/header";
import CheckboxFilter from "../../components/checkbox/checkboxSpecialities";
import CheckboxMethods from "../../components/checkbox/checkboxMethods";
import CheckboxTitration from "../../components/checkbox/checkboxTitration";
import CheckboxArea from "../../components/checkbox/checkboxArea";
import CheckboxAttendHours from "../../components/checkbox/checkboxAttendHours"; 

import * as ProfessionalsServices from "../../services/api/ProfessionalsServices"; 
import {isMobile} from 'react-device-detect';


const filterCity = async (inputValue) => {
  const array = [];
  try {
    const resp = await ProfessionalsServices.searchCity(inputValue);
    if (inputValue.length > 0)
      resp.data.results.forEach((element) => {
        if (element[0].city !== "")
          array.push({
            label: `${element[0]?.city} - ${element[0]?.state}`,
            value: `${element[0]?.city}`,
            name: `${element[0]?.city}`,
          });
      });

    return array.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  } catch (error) {}
};

const loadOptions = (inputValue, callback) => {
  setTimeout(async () => {
    callback(await filterCity(inputValue));
  }, 1000);
}; 

const getCities = [
  {
    label: "Selecione",
    value: "%",
    name: "Selecione",
  },
];

const useStyles = makeStyles((theme) => ({
  colDois: {
    marginLeft: 0,
    "@media (max-width: 500px)": {
      marginLeft: 0,
    },
  },
  icone: {
    color: "#C4C4C4",
    margin: "0 0 -30px 0",
  },
  imagem: {
    objectFit: "cover!important",
    borderRadius: "50%",
    height: "150px",
    width: "150px",
    margin: "0 50px 0 0",
    "@media (max-width: 500px)": {
      justifyContent: "center",
      margin: "0 auto",
    },
  },
  gridImg: {
    textAlign: "left",
    "@media (max-width: 500px)": {
      textAlign: "center",
    },
  },

  formControl: {
    color: "#C4C4C4",
    margin: "0 0 30px 0",
    padding: "0 12px 20px",
    display: "flex",
  },
  root: {
    backgroundColor: "#fff",
    border: 0,
    borderRadius: 30,
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    display: "flex",
    margin: "50px 10% 50px 25%",
    padding: "10px 0 10px 31px",
    width: "50%",
    "@media (max-width: 500px)": {
      margin: "50px 10% 5px 15%",
      width: "70%",
    },
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    padding: "10px",
  },
  iconButton: {
    backgroundColor: "#54B5CF",
    border: 0,
    borderRadius: "0 30px 30px 0",
    color: "#fff",
    height: 50,
    width: "15%",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#0facc4",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  buscar: {
    display: "flex",
    alignItems: "center",
    width: "1200px",
    "@media (max-width: 500px)": {
      width: "100%",
      margin: "0 0 0 -0px",
    },
  },

  container2: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  paperSeg: {
    backgroundColor: "#fff",
    borderRadius: 50,
    color: theme.palette.text.secondary,
    display: "flex",
    marginBottom: theme.spacing(8),
    padding: 50, 
    textWrap: 'balance',
    width: '700px',
    maxWidth: '100%',
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 500px)": {
      display: "grid",
      padding: "10px",
      width: '350px',
      textWrap: 'balance'
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  grids: {
    padding: "0 100px 0 100px",
    display: "flex",
    "@media (max-width: 500px)": {
      display: "grid",
      padding: "10px",
    },
  },
  toggle: {
    borderWidth: "0 0  2.5px 0",
    borderStyle: "Solid",
    borderColor: "#54B5CF",
    borderRadius: 0,
    width: "100%",
  },

  bntTitulo: {
    textTransform: "capitalize",
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "18px",
    display: "flex",
  },

  nome: {
    fontSize: "21px",
    fontFamily: "Roboto",
    fontWeight: 900,
    color: "#55B5CF",
    "&:hover": {
      color: "#0facc4",
      textDecoration: "none",
    },
  },
  titulo: {
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 900,
    color: "#55B5CF",
    margin: "10px 0 10px 0",
    "@media (max-width: 500px)": {
      fontSize: "15px",
    },
  },
  subTitulo: {
    fontFamily: "Roboto",
    fontSize: "17px",
    fontWeight: 500,
    color: "#7ECDE2",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  texto: {
    fontFamily: "Roboto",
    fontSize: "17px",
    fontWeight: 500,
    color: "#C4C4C4",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  textoDetalhe: {
    fontFamily: "Roboto",
    fontSize: "17px",
    fontWeight: 500,
    color: "#C4C4C4",
    "@media (max-width: 500px)": {
      fontSize: "0px",
    },
  },
  textoDetalheMolibe: {
    color: "#C4C4C4",
    fontFamily: "Roboto",
    fontSize: "0px",
    fontWeight: 500,
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  bntAtendimento: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#0facc4",
    color: "#666666",
    width: "70%",
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Roboto",
    borderRadius: "4px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  linkContato: {
    textDecoration: "none",
    color: "#666666",
    fontSize: 14,
    "&:hover": {
      textDecoration: "none",
      color: "#0facc4",
    },
  },
  crfa: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "21px",
    color: "#C4C4C4",
  },
  linkWrapper: {
    "@media (max-width: 500px)": {
      textAlign: "center",
    },
  },
  linkSaiba: {
    color: "#54B5CF",
    textDecoration: "underline",
    "&:hover": {
      color: "#0facc4",
    },
    "@media (max-width: 500px)": {},
  },
  filtro: {
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "32px",
  },
  start: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    margin: "0 0 0 20px",
  },
  pag: {
    display: "flex",
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: 700,
    color: "#54B5CF",
    "@media (max-width: 500px)": {
      fontSize: "80%",
    },
    "& nav": {
      paddingTop: "0!important",
    },
  },
  paginas: {
    "& ul > li": {
      margin: "0!important",
    },
    "& .Mui-selected": {
      backgroundColor: "transparent",
      color: "#54B5CF",
      fontSize: 22,
      fontFamily: "Montserrat",
      fontWeight: 700,
      "@media (max-width: 500px)": {
        fontSize: "80%",
      },
    },
    "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
      {
        backgroundColor: "transparent",
        color: "#666666",
        fontSize: 20,
        fontFamily: "Montserrat",
        fontWeight: 400,
        padding: -10,
        "@media (max-width: 500px)": {
          fontSize: "80%",
        },
      },
  },
}));

export default function ProfissionaisSupervisor() {
  const routeParams = window.location.search.split("category=")[1];
  const classes = useStyles(); 
  const [page, setPage] = React.useState(1);
  const [show, toggleShow] = React.useState(true); 
  // eslint-disable-next-line no-unused-vars
  const [showHorAtendimento, horAtendimento] = React.useState(false);
  const [showRegAtendimento, regAtendimento] = React.useState(true);
  const [showTipAtendimento, tipoAtendimento] = React.useState(true);
  const [showTitulacao, titulacao] = React.useState(true);
  const [showAreaAtendimento, areaAtendimento] = React.useState(true);

  // eslint-disable-next-line no-unused-vars
  const [isClearable, setIsClearable] = React.useState(true); 

  const [professionals, setProfessionals] = React.useState([]);

  const [filters, setFilters] = React.useState({
    term: "",
    specialities: [],
    title: [],
    period: [],
    type: [],
    area: [],
    location: [],
  });

  const onTermChange = (e) => {
    setFilters({ ...filters, term: e.target.value });
  };

  const onSpecialitiesChange = (values) => {
    setFilters({ ...filters, specialities: values });
  };

  const onTitrationChange = (values) => {
    setFilters({ ...filters, title: values });
  };

  const onPeriodChange = (values) => {
    setFilters({ ...filters, period: values });
  };

  const onTypeChange = (values) => {
    setFilters({ ...filters, type: values });
  };

  const onAreaChange = (values) => {
    setFilters({ ...filters, area: values });
  };

  const handleChange = (event, value) => {
    if (value > 0 && value <= professionals?.totalPages) {
      setPage(value);
    }
  };

  const handleChangeSelect = (e) => {
    setFilters({ ...filters, location: e?.value });
  };

  const handleErase = () => {
    setFilters({
      term: "",
      specialities: [],
      title: [],
      period: [],
      type: [],
      area: [],
      location: [],
    });
  
    window.location.reload(); 
  };


  const onCollapse = (e) =>{ 
    if(e === 'areaAtendimentos'){  
      showAreaAtendimento ? areaAtendimento(false) : areaAtendimento(true)
    }

    if(e === 'titulacao'){  
      showTitulacao ? titulacao(false) : titulacao(true)
    }

    if(e === 'tipoAtendimento'){  
      showTipAtendimento ? tipoAtendimento(false) : tipoAtendimento(true)
    }

    if(e === 'regAtendimento'){  
      showRegAtendimento ? regAtendimento(false) : regAtendimento(true)
    }

    if(e === 'toggleShow'){  
      show ? toggleShow(false) : toggleShow(true)
    }
     
  }

  React.useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.list(page, 6, filters, true);
      setProfessionals(resp.data);

    })();
  }, [filters, page]);


  React.useEffect(() => {
    (async () => {

      if(isMobile === true ){
        console.log('load')
        areaAtendimento(false);
        toggleShow(false);
        titulacao(false);
        tipoAtendimento(false);
        regAtendimento(false);
      } 

    })();
  }, []);  
  
  
  React.useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.list(page, 6, filters, true);
      setProfessionals(resp.data);

      const city = await ProfessionalsServices.getCities();

      city.data.results.forEach((element) => {
        getCities.push({
          label: `${element.city} - ${element.state}`,
          value: `${element.city}`,
          name: `${element.city}`,
        });
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClick = async () => {
    const resp = await ProfessionalsServices.list(page, 6, filters, true);
    setProfessionals(resp.data);
  };

  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <InputBase
          className={classes.input}
          inputProps={{ "aria-label": "Lista de Profissional" }}
          value={filters.term}
          onChange={onTermChange}
          placeholder="Buscar por nome ou expertise"
        />
        <Button className={classes.iconButton} onClick={onSearchClick}>
          Buscar
        </Button>
      </Grid>
      <Grid className={classes.grids}>
   
        {/* Inicio da Segunda Coluna*/}

        <Grid item md={12} className={classes.colDois} style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
          <p className={classes.bntTitulo}>
            {routeParams?.toLocaleUpperCase()} 
          </p>
          {professionals?.results?.length === 0 && `Não foram encontrados resultados ao termo: ${filters.term}`}
          {professionals?.results?.map((data, index) => (
            <div key={data?.id}>
              <Grid className={classes.paperSeg}>
                <Grid item md={5}>
                  <Grid className={classes.gridImg}>
                    {data?.picture ? (
                      <img
                        className={classes.imagem}
                        src={data?.picture}
                        alt={data?.picture}
                      />
                    ) : (
                      <Image className={classes.imagem} src={imgAp} />
                    )}
                  </Grid>

                  <Typography className={classes.titulo}>Contato</Typography>
                  <Grid>
                    <Grid className={classes.linkContato}>
                      {data?.whatsapp ? (
                        <Link
                          className={classes.linkContato}
                          href={`https://api.whatsapp.com/send?phone=${(data?.whatsapp).replace(
                            /\D/g,
                            ""
                          )}`}
                          target="_blank"
                        >
                          <WhatsAppIcon
                            desabled="true "
                            style={{ color: "#25d366", padding: "0 5px 0 0" }}
                          />{" "}
                          {data?.whatsapp}
                        </Link>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid>
                      {data?.phone ? (
                        <Link
                          className={classes.linkContato}
                          href={`mailto:${data?.phone}`}
                        >
                          <PhoneIcon
                            desabled="true"
                            style={{ color: "#34b7f1", padding: "0 5px 0 0" }}
                          />{" "}
                          {data?.phone}
                        </Link>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid>
                      {data?.email ? (
                        <Link
                          className={classes.linkContato}
                          href={`mailto:${data?.email}`}
                        >
                          <MailOutlineIcon
                            desabled="true"
                            style={{ color: "#34b7f1", padding: "0 5px 0 0" }}
                          />{" "}
                          {data?.email}
                        </Link>
                      ) : (
                        <span></span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={7} sm={6}>
                  <Row style={{ display: "flex" }}>
                    <Grid item md={12}>
                      <Typography>
                        <Link
                          href={"/profissional-detalhes/" + data?.id}
                          className={classes.nome}
                        >
                          {data?.name}
                        </Link>
                      </Typography>
                    </Grid>

                    {/* media das notas do profissional*/}

                    {/* <Grid item md={6} className={classes.start}>
                      <Rating
                        name="hover-feedback"
                        value={5}
                        disabled
                        precision={1}
                      />
                    </Grid> */}
                  </Row>
                  <Row style={{ display: "flex" }}>  
                    <Grid item md={12}>
                    <Typography className={classes.titulo} style={{ whiteSpace: "pre-wrap" }}>
                        Titulação máxima{" "}
                      </Typography>
                      <Typography className={classes.textTer} style={{ whiteSpace: "pre-wrap" }}>
                        {data?.title}
                      </Typography>
                      <Typography className={classes.titulo} style={{ whiteSpace: "pre-wrap" }}>
                        Expertise{" "}
                      </Typography>
                      <Typography className={classes.textTer} style={{ whiteSpace: "pre-wrap" }}>
                        {data?.expertise}
                      </Typography>
                    </Grid> 
                    <Typography
                      className={classes.textoDetalhe}
                      style={{ whiteSpace: "pre-wrap" }}
                    ></Typography>
                    {data?.description?.length <= 120 && (
                      <p style={{ flexShrink: 1 }}>{data?.description}</p>
                    )}
                    {data?.description?.length > 120 && (
                      <p
                        style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                        }}
                      >
                        {data?.description.substr(0, 120) + "..."}{" "}
                      </p>
                    )}
                    <br /> 
                    <Typography className={classes.textoDetalheMolibe}>
                      {data?.kind}
                      <br />
                      {data?.institution_name}
                    </Typography>
                  </Row>
                  <Row style={{ display: "flex", textWrap: 'balance'}}>
                    {data?.professionalAttendenceMethods.map((home) => (
                      <div key={home.id}>
                        <Grid item md={12} style={{ marginBottom: "10px" }}>
                          <Typography className={classes.titulo}>
                            {home.attendenceMethod.name}
                          </Typography>
                          <Row style={{ display: "flex" }}>
                            <Grid item md={6}>
                              <Typography className={classes.textoDetalhe}>
                                Valor R${" "}
                                {parseFloat(home.value)
                                  .toFixed(2)
                                  .replace(".", ",")}
                              </Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography className={classes.textoDetalhe}>
                                Duração: {home.schedule} minutos
                              </Typography>
                            </Grid>
                          </Row>
                          <Typography
                            className={classes.titulo}
                            style={{ textAlign: "center" }}
                          >
                            Horário de Atendimento
                          </Typography>
                          <Row
                            style={{
                              display: "flex",
                              placeContent: "space-between",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {JSON.parse(home?.period).includes("Morning") ? (
                              <p
                                style={{ width: "auto", color: "#54B5CF" }}
                                className={classes.textoDetalhe}
                              >
                                Manhã
                              </p>
                            ) : (
                              ""
                            )}
                            {JSON.parse(home?.period).includes("Afternoon") ? (
                              <p
                                style={{ width: "auto", color: "#54B5CF" }}
                                className={classes.textoDetalhe}
                              >
                                Tarde
                              </p>
                            ) : (
                              ""
                            )}
                            {JSON.parse(home?.period).includes("Night") ? (
                              <p
                                style={{ width: "auto", color: "#54B5CF" }}
                                className={classes.textoDetalhe}
                              >
                                Noite
                              </p>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Grid>
                      </div>
                    ))}
                    <Typography className={classes.linkWrapper}>
                      <Link
                        href={"/profissional-detalhes-supervisor/" + data?.id}
                        className={classes.linkSaiba}
                      >
                        Saiba Mais
                      </Link>
                    </Typography>
                  </Row>
                </Grid>
              </Grid>
            </div>
          ))}
          <Grid className={classes.pag} style={{ justifyContent: "end" }}>
            <Grid
              style={{
                visibility: page > 1 ? "visible" : "hidden",
                cursor: "pointer",
              }}
              onClick={() => handleChange(page, page - 1)}
            >
              Anterior
            </Grid>

            <Grid>
              <Pagination
                siblingCount={0}
                count={professionals?.totalPages}
                page={page}
                className={classes.paginas}
                classes={{ selected: classes.selected }}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              style={{
                visibility:
                  page < professionals?.totalPages ? "visible" : "hidden",
                cursor: "pointer",
              }}
              onClick={() => handleChange(page, page + 1)}
            >
              Próximo
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
