import "../components/styles/style.css";
import { makeStyles } from "@material-ui/core/styles";
import Image from "react-bootstrap/Image";
import { Container, Dropdown, Navbar, Nav } from "react-bootstrap";
import logoProfono from "../assets/img/logo.png";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../providers/useUserContext";
import { signOut } from "../services/api/AuthService";
import { useHistory } from "react-router-dom";
import * as PlansServices from '../services/api/PlansServices';

import { useMemo, useState, useEffect, Fragment, useRef } from "react";

import * as ProfessionalsServices from "../services/api/ProfessionalsServices";


const useStyles = makeStyles({
  brand: {
    borderColor: "#fff",
    borderStyle: "Solid",
    borderWidth: "0 1px  0 0",
    margin: "0 0 0 30px",
    padding: "0 10px 0 0",
    "@media (max-width: 500px)": {
      borderWidth: "0 0  0 0",
      margin: "0 5px 0 5px",
    },
  },
  SegBrand: {
    margin: "0 0 0 30px",
    "@media (max-width: 500px)": {
      margin: "0 0 0 0px",
    },
  },
  imgBrand: {
    width: "80%",
    "@media (max-width: 500px)": {
      width: "80%",
    },
  },
  imgMinhaFono: {
    padding: "0 0 0 0",
    width: "60%",
    "@media (max-width: 500px)": {
      width: "60%",
    },
  },

  nav: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 5% 0 20%",
    width: "100%",
  },

  dropdownBotao: {
    background: "linear-gradient(45deg, #FFF 30%, #FFFF 90%)",
    border: "none !important",
    borderRadius: "25px",
    boxShadow: "none !important",
    color: "#54B5CF !important",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    height: "40px",
    letterSpacing: "0.085em",
    padding: "10px 48px",
    position: "sticky",
    zIndex: "1000",
    "@media (max-width: 500px)": {
      margin: "10px 0 -7px 0",
    },
  },

  dropDownMenu: {
    backgroundColor: "#F2F2F2",
    border: 0,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    margin: "0px auto",
    padding: "20px",
    top: "55% !important",
    width: "100%",
    zIndex: "1",
    "@media (max-width: 500px)": {
      padding: "25px 0 10px 10px",
      width: "68%",
      margin: "0 10px 0 0",
    },
  },

  dropDownItems: {
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "0.085em",
    margin: "10px 0 0 0",
    padding: "10px 0 10px 0",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#000",
    },
    "@media (max-width: 500px)": {
      fontSize: 14,
      margin: "0 0 10px 0",
      "&:hover": {
        textDecoration: "underline",
        backgroundColor: "rgba(0, 0, 0,0)",
        color: "#000",
      },
    },
  },
  a: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "400",
    margin: "10px 0 0 0",

    "&:hover": {
      borderColor: "#fff",
      borderStyle: "Solid",
      borderWidth: "0 0 2px 0",
      color: "#fff",
    },
    "@media (max-width: 500px)": {
      fontSize: 15,
      margin: "15px 0 15px 0",
      width: "70%",
    },
  },
  navBar: {
    backgroundColor: "#54B5CF",
    height: 100,
    "@media (max-width: 500px)": {
      height: "auto",
    },
  },
  collapse: {
    "@media (max-width: 500px)": {
      margin: "0 0 20px -70px",
    },
  },
});

const Header = (props) => {
  const classes = useStyles();
  const user = useUserContext();
  const history = useHistory();

  const [plan, setPlan] = useState(null); 

  useEffect(() => {
    (async () => {
        const resp = await PlansServices.get();
        setPlan(resp.data[0]);    
    })();
  }, []);

  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut();
    history.push("/admin/dashboard/login");
  };

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const resp = await ProfessionalsServices.getProfile(); 
      setProfile(resp.data);
    })();
  }, []);

  return (
    <div>
      <Navbar className={classes.navBar} expand="lg">
        <Container fluid>
          <Navbar.Brand href="/" className={classes.SegBrand}>
            <Image className={classes.imgBrand} src={logoProfono} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className={classes.collapse}>
            <Nav className={classes.nav}>
              <NavLink
                style={{
                  borderColor: "#fff",
                  borderStyle: "Solid",
                  borderWidth: "0 0 2px 0",
                  color: "#fff",
                }}
                to="/profissionais-cards"
                className={classes.a}
              > 
                BUSCA DE PROFISSIONAIS
              </NavLink>
              <NavLink
                style={{
                  borderColor: "#fff",
                  borderStyle: "Solid",
                  borderWidth: "0 0 2px 0",
                  color: "#fff",
                }}
                to="/assinatura-profissionais"
                className={classes.a}
              >
                ASSINATURA PARA PROFISSIONAIS
              </NavLink>
              <Dropdown>
                <Dropdown.Toggle
                  variant="/profissionais-cards"
                  id="dropdown-basic"
                  className={classes.dropdownBotao}
                >
                  ÁREA DO ASSINANTE
                </Dropdown.Toggle>
                {user ? (
                  <Dropdown.Menu className={classes.dropDownMenu}>
                    <Dropdown.Item
                      className={classes.dropDownItems} 
                    >
                    <NavLink 
                      className={classes.dropDownItems} 
                      to="/admin/profissional/perfil" 
                    >
                    MEU PERFIL
                    </NavLink>   
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={classes.dropDownItems} 
                    >
                    <NavLink 
                      className={classes.dropDownItems} 
                      to="/admin/profissional/assinatura" 
                    >
                    ASSINATURA
                    </NavLink>   
                    </Dropdown.Item>
                    {profile?.cpf_cnpj ? (
                      <>
                    <Dropdown.Item
                      className={classes.dropDownItems} 
                    >
                    <NavLink 
                      className={classes.dropDownItems} 
                      to="/profissionais-supervisor" 
                    >
                    SUPERVISORES
                    </NavLink>   
                    </Dropdown.Item>
                    
                      <Dropdown.Item
                      className={classes.dropDownItems} 
                    > 
                    <NavLink 
                      className={classes.dropDownItems} 
                      to={plan?.plans_id !== null &&  plan ? '/apps': '/admin/profissional/assinatura'} 
                    >
                    APPS PRÓ-FONO
                    </NavLink>  
                    </Dropdown.Item> 
                    <Dropdown.Item
                      className={classes.dropDownItems} 
                    >
                    <NavLink 
                      className={classes.dropDownItems} 
                      to={plan?.plans_id !== null &&  plan ? '/admin/profissional/books': '/admin/profissional/assinatura'} 
                    >
                    <i>EBOOKS</i> PRÓ-FONO
                    </NavLink>  
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={classes.dropDownItems} 
                    >
                    <NavLink 
                      className={classes.dropDownItems} 
                      to={plan?.plans_id !== null &&  plan ? '/playlists': '/admin/profissional/assinatura'}
                    > 
                    VÍDEOS PRÓ-FONO
                    </NavLink> 
                    </Dropdown.Item>
                      </>
                    ):(<>
                    
                    </>)}
                   
                    <Dropdown.Item
                      className={classes.dropDownItems}
                      href="/admin/dashboard/login"
                      onClick={(e) => handleSignOut(e)}
                    >
                      SAIR
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu className={classes.dropDownMenu}>
                    <Dropdown.Item
                      className={classes.dropDownItems}
                      href="/admin/dashboard/login"
                    >
                      LOGIN
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
