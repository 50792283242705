import React from 'react';
import Image from 'react-bootstrap/Image';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'; 

import useStyles from '../../../components/styles/profissional.styles';
import imgAP from '../../../assets/img/imagemAP.jpg';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import MenuPerfil from '../../../components/menuPerfil';



export default function Perfil() {
    const classes = useStyles();
    const {
        newGrid, menu, perfil, page,  pageGeral,  imagemCurso, textCurso, gridCurso, botaoApp, tituloperfil,
    } = classes;
    return (

        <div style={{ backgroundColor: '#F8F8FA' }}>
            <Header />
            <div>
                <Grid className={newGrid} >
                    <Grid item md={3} className={menu}>
                        <MenuPerfil />

                    </Grid>
                    <Grid item md={9} className={perfil}>
                        <Grid className={page}>
                            <Grid item md={12} >
                                <Typography className={tituloperfil}>APPS PRÓ-FONO</Typography>
                            </Grid>
                        </Grid>

                        <Grid className={ pageGeral}>
                            <Grid item md={12}>
                                <Image
                                    className={imagemCurso}
                                    src={imgAP}
                                    alt="Imagem Curso"
                                />
                            </Grid>

                            <Grid className={gridCurso}>
                            <Grid item md={12}>
                               <Typography className={textCurso}>Os aplicativos são fornecidos pelo Grupo Pró-Fono,
                                parceira da Minha Fono®, clique no botão abaixo e tenha acesso aos aplicativos da Pró-Fono</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} style={{textAlign:'center', marginTop:30}}>
                               <Link className={botaoApp} href="https://profono.com.br/prod-cat/conteudodigitalaplicativosedvds/" target="_blank">ACESSAR MEUS APLICATIVOS</Link>
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>
            </div>
            <Footer />
        </div>
    );
}


