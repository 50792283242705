import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import Image from "react-bootstrap/Image";

import imgAP from "../../assets/img/faca_parte_da_equipe.png";

import Header from "../../components/header";
import Footer from "../../components/footer";
import imagemAcessoVideo from "../../assets/img/videos_profono_acesso.png";
import imagemAppProfono from "../../assets/img/apps_profono_acesso.png";
import imagemDivulgacao from "../../assets/img/divulgação_profono_acesso.png";

import { Container, Row, Col } from "react-bootstrap";

const useStyles = makeStyles({
  botao: {
    backgroundColor: "#54B5CF",
    border: "none",
    borderRadius: "30px",
    color: "#fff",
    display: "flex",
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: 400,
    justifyContent: "center",
    margin: "20px auto",
    width: "80%",
    "&:hover": {
      backgroundColor: "#0facc4",
      color: "#fff",
      textDecoration: "none",
    },
    "@media (max-width: 500px)": {
      backgroundColor: "#54B5CF",
      fontSize: "18px",
      margin: "0 auto",
      padding: "10px 20px 10px 20px",
      width: "100%",
    },
  },

  colUm: {
    padding: "50px 50px 0px 50px",
    textAlign: "center",
    "@media (max-width: 500px)": {
      padding: "0 65px 0 65px",
    },
  },

  colDois: {
    backgroundColor: "#fff",
    borderRadius: "23px",
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    padding: "50px",
    "@media (max-width: 500px)": {
      padding: "30px 20px 30px 20px",
      margin: "0 4px 0 4px",
    },
  },
  colTres: {
    padding: "50px 0px 0px 50px",
    "@media (max-width: 500px)": {
      padding: "20px 20px 30px 20px",
    },
  },

  linha: {
    borderWidth: "0px 1px 0px 0px",
    borderColor: "#C4C4C4",
    borderStyle: "solid",
    padding: "0 0 0 4%",
    "@media (max-width: 500px)": {
      borderWidth: "0px 0px 1px 0px",
      borderColor: "#C4C4C4",
      borderStyle: "solid",
    },
  },

  p: {
    color: "#54B5CF",
    fontSize: "37px",
    fontFamily: "Montserrat",
    fontWeight: 800,
    padding: "50px 0px 0px 0px",
    "@media (max-width: 500px)": {
      fontSize: "20px",
      textAlign: "center",
    },
  },

  p2: {
    fontFamily: "Montserrat",
    color: "#666666",
    fontSize: "20px",
    padding: "20px 0px 0px 0px",
  },

  p3: {
    fontSize: "51px",
    color: "#C4C4C4",
    fontFamily: "Montserrat",
    fontWeight: 400,
    "@media (max-width: 500px)": {
      fontSize: "25px",
      textAlign: "center",
    },
  },

  p4: {
    fontSize: "85px",
    color: "#54B5CF",
    fontFamily: "Montserrat",
    fontWeight: 800,
    margin: "-50px 0px 0px 0px",
    "@media (max-width: 500px)": {
      fontSize: "45px",
      margin: "-30px 0px 0px 0px",
      textAlign: "center",
    },
  },

  p5: {
    color: "#666666",
    fontSize: "20px",
    fontFamily: "Roboto",
    "@media (max-width: 500px)": {
      fontSize: "15px",
      margin: "0 10px 0 10px",
    },
  },

  p6: {
    fontSize: "30px",
    fontFamily: "Montserrat",
    fontWeight: 800,
    color: "#54B5CF",
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: "18px",
      margin: "0 0 20px 0 ",
    },
  },
  imagem: {
    height: "700px",
    width: "100%",
    objectFit: "cover",

    "@media (max-width: 500px)": {
      width: "100%",
      height: "200px",
    },
  },
  imagemCirculo: {
    width: "70%",
    "@media (max-width: 500px)": {
      width: "80%",
    },
  },
  r: {
    color: "#54B5CF",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: 800,
    textAlign: "right",
    position: "relative",
    top: "-5px",
    "@media (max-width: 500px)": {
      fontSize: "20px",
      margin: "250% 0 0 0",
    },
  },
});

export default function Sobre() {
  const classes = useStyles();
  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />

      <Container fluid>
        {/* 
                <Row>
                    <Col sm={12} style={{ padding: '0px' }}>
                        <Image
                            className={classes.imagem}
                            src={imgAP}
                        />
                    </Col>

                </Row> */}
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <p className={classes.p}>Minha Fono®</p>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p className={classes.p5}>
              O Minha Fono® é uma Plataforma de assinaturas idealizada por Ciro
              Campanatti que tem diversos objetivos: captação de clientes para
              as terapias dos diversos profissionais da Área da Saúde;
              localização de profissionais de forma prática e rápida; acesso a
              vídeos e lives explicativas de assuntos de interesse aos
              profissionais. Em breve: acesso a aplicativos auxiliares de
              terapias; acesso a profissionais habilitados a dar supervisão
              clínica nas diversas áreas.
            </p>
            <div>
              <h2 className={classes.p6} style={{ textAlign: "left" }}>
                Descrição
              </h2>

              <p>
                <strong>Nome completo do produto:</strong> Minha Fono®
              </p>
              <p>
                <strong>Idealização:</strong> Ciro Campanatti.
              </p>
              <p>
                <strong>Organização:</strong> Dra. Heliane Campanatti.
              </p>
              <p>
                <strong>Programação:</strong> Rafael de Medeiros.
              </p>
              <p>
                <strong>Realização:</strong> Pró-Fono.
              </p>
              <p>
                <strong>Requisitos:</strong> Compatível com os navegadores:
                Google Chrome, Mozilla Firefox, Safari, Microsoft Edge e Opera.
                Nos dispositivos móveis, exige sistema operacional Android 11 ou
                superior e IOS 14 ou superior.
              </p>
              <p>
                <strong>Versão:</strong> 1.0.0
              </p>
              <p>
                <strong>Lançamento:</strong> 2023
              </p>
              <p>
                <strong>Descrição</strong>
              </p>
              <p>
                O Minha Fono®, idealizado por Ciro Campanatti, é uma plataforma
                de assinaturas que tem diversos objetivos:
              </p>
              <ol>
                <li>
                  Captação de clientes para as terapias dos diversos
                  profissionais da Área da Saúde (Fonoaudiologia, Pedagogia,
                  Psicologia e Fisioterapia).
                </li>
                <li>Localização de profissionais de forma prática e rápida.</li>
                <li>
                  Acesso a vídeos e lives explicativas de assuntos de interesse
                  aos profissionais.
                </li>
                <li>Em breve: acesso a aplicativos auxiliares de terapias.</li>
                <li>
                  Em breve: acesso a profissionais habilitados a dar supervisão
                  clínica nas diversas áreas.
                </li>
              </ol>
              <p>&nbsp;</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
