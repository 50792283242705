import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import Image from 'react-bootstrap/Image';

import imgAP from '../../assets/img/faca_parte_da_equipe.png';

import Header from '../../components/header';
import Footer from '../../components/footer';
import imagemAcessoVideo from '../../assets/img/videos_profono_acesso.png'; 
import imagemAppProfono from '../../assets/img/apps_profono_acesso.png';
import imagemDivulgacao from '../../assets/img/divulgação_profono_acesso.png';
import imagemSupervisao from '../../assets/img/apps_supervisão_acesso.png';

import {
    Container, Row, Col

} from 'react-bootstrap'



const useStyles=makeStyles({
    botao: {
        backgroundColor: '#54B5CF',
        border: 'none', borderRadius: '30px',
        color: '#fff',
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: 400,
        justifyContent: 'center',
        margin: '20px auto',
        width: '80%',
        '&:hover': {
			backgroundColor:'#0facc4',
			color:'#fff',
			textDecoration: 'none',
        },
        "@media (max-width: 500px)": {
            backgroundColor: '#54B5CF',
            fontSize: '18px',
            margin: '0 auto',
            padding:'10px 20px 10px 20px',
            width: '100%',
        }
    },

    colUm: {
        padding: '50px 50px 0px 50px',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            padding: '0 65px 0 65px',
        }
    },

    colDois: {
        backgroundColor: '#fff',
        borderRadius: '23px',
        boxShadow: ' 0 0 20px rgba(0, 0, 0, 0.1)',
        padding: '50px',
        "@media (max-width: 500px)": {
            padding: '30px 20px 30px 20px',
            margin: '0 4px 0 4px'
        }
    },
    colTres: {
        padding: '50px 0px 0px 50px',
        "@media (max-width: 500px)": {
            padding: '20px 20px 30px 20px',
        }
    },

    linha: {
        borderWidth: '0px 1px 0px 0px',
        borderColor: '#C4C4C4',
        borderStyle: 'solid',
        padding: '0 0 0 4%',
        "@media (max-width: 500px)": {
            borderWidth: '0px 0px 1px 0px',
            borderColor: '#C4C4C4',
            borderStyle: 'solid',
        }
    },

    p: {
        color: '#54B5CF',
        fontSize: '37px',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        padding: '50px 0px 0px 0px',
        "@media (max-width: 500px)": {
        fontSize: '20px',
        textAlign: 'center'
        }
    },

    p2: {
        fontFamily: 'Montserrat',
        color: '#666666',
        fontSize: '20px',
        padding: '20px 0px 0px 0px',
    },

    p3: {
        fontSize: '51px',
        color: '#C4C4C4',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        "@media (max-width: 500px)": {
            fontSize: '25px',
            textAlign: 'center'
        }

    },

    p4: {
        fontSize: '85px',
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        margin: '-50px 0px 0px 0px',
        "@media (max-width: 500px)": {
            fontSize: '45px',
            margin: '-30px 0px 0px 0px',
            textAlign: 'center'
        }
    },

    p5: {
        color: '#666666',
        fontSize: '20px',
        fontFamily: 'Roboto',
        "@media (max-width: 500px)": {
            fontSize: '15px',
            margin: '0 10px 0 10px'
        }
    },

    p6: {
        fontSize: '30px',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        color: '#54B5CF',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            fontSize: '18px',
            margin: '0 0 20px 0 '
        }
    },
    imagem: {
        height: '700px',
        width: '100%',
        objectFit: 'cover',

        "@media (max-width: 500px)": {
            width: '100%',
            height: '200px',
        }
    },
    imagemCirculo: {
        width: '70%',
        "@media (max-width: 500px)": {
            width: '80%',     
        }
    },
    r: {
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '22px',
        fontWeight: 800,
        textAlign: 'right',
        position: 'relative',
        top: '-5px',
        '@media (max-width: 500px)': {
            fontSize: '20px',
            margin: '250% 0 0 0',
        }
    }


});

export default function AssinaturaProfissionais() {
    const classes=useStyles();
    return (
        <div style={{ backgroundColor: '#F8F8FA'}}>
            <Header />

            <Container fluid >
{/* 
                <Row>
                    <Col sm={12} style={{ padding: '0px' }}>
                        <Image
                            className={classes.imagem}
                            src={imgAP}
                        />
                    </Col>

                </Row> */}
            </Container>

            <Container> 
                <Row>
                    <Col sm={8}><p className={classes.p}>ASSINATURA</p></Col>

                </Row>
                <Row className={classes.colDois}> 
                    <Col sm={12} className={classes.colTres} >

                        <Grid style={{ display: 'flex' }}>
                            <Grid item sm={12}><Typography className={classes.p6}> GARANTA SEUS BENEFÍCIOS<br />E FAÇA PARTE DA EQUIPE<br /> MINHA FONO<span className={classes.r}>®</span>!</Typography>
                            </Grid>
                        </Grid>

                        <Link className={classes.botao} href="https://minhafono.com.br/admin/dashboard/registro" target="_blank">QUERO SER ASSINANTE!</Link>{''}
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}><p className={classes.p}>BENEFÍCIOS AOS ASSINANTES</p></Col>

                </Row>
                <Row> 
                    <Col sm className={classes.colUm}>
                        <Image
                            className={classes.imagemCirculo}
                            src={imagemAcessoVideo} 

                        />
                        <p className={classes.p2}>Acesso a Vídeos da Pró-Fono</p>

                    </Col>

                    <Col sm className={classes.colUm}>

                        <Image
                            className={classes.imagemCirculo}
                            src={imagemAppProfono}
                        />
                        <p className={classes.p2}>Acesso a <br /> aplicativos Pró-Fono</p>

                    </Col>

                    <Col sm className={classes.colUm}>

                        <Image
                            className={classes.imagemCirculo}
                            src={imagemDivulgacao}
                        />
                        <p className={classes.p2}>Visibilidade e divulgação <br />do seu consultório</p>

                    </Col>

                    <Col sm className={classes.colUm}>

                        <Image
                            className={classes.imagemCirculo}
                            src={imagemSupervisao}
                        />
                        <p className={classes.p2}>Acesso a Supervisão Clínica</p>

                    </Col>
                </Row>

                <Row>
                    <Col sm={12}><p className={classes.p}>ATENDIMENTO PRESENCIAL E TELEATENDIMENTO</p></Col>

                </Row>
                <Row>
                    <Col sm={6}>


                        <p className={classes.p5}>
                            Ao tornar-se assinante, o profissional receberá um formulário para criar o seu perfil com todas as informações.<br />
                            <br />
                            Seus dados ficarão disponíveis a todos os usuários que estiverem procurando a sua especialidade, seja para atendimento online ou presencial.</p>

                    </Col>

                    <Col sm={6}>


                        <p className={classes.p5}>
                            Os usuários também podem fazer avaliação e comentários sobre o profissional, contribuindo para a sua transparência e divulgação.<br />
                            <br />
                            Importante lembrar que para o teleatendimento, o profissional deve ter sua própria plataforma (como Google Meet ou Zoom Meeting, etc.),
                            pois o Minha Fono® é uma plataforma intermediadora.</p>

                    </Col>


                </Row>

            </Container>
            <Footer />
        </div>
    )
}