import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../services/api/AuthService";
import { useDispatch } from "react-redux";
import * as types from "../constants";

const UserContext = React.createContext({});

export const UserProvider = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authReducer?.user);

  React.useEffect(() => {
    (async () => {
      const user = await getCurrentUser(); 
      if (user) {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          id: user.id,
          email: user.email,
          name: user.name,
        });
      }
    })();
  }, [dispatch]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export const useUserContext = () => React.useContext(UserContext);
