import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Image from 'react-bootstrap/Image';

import useStyles from '../../../components/styles/profissional.styles';
import imgAP from '../../../assets/img/imagemAP.jpg';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import MenuPerfil from '../../../components/menuPerfil';

export default function Perfil() {
    const classes = useStyles();
    const {
        bntCurso,newGrid, menu,perfil,  page, page2, titulo, imagemCurso, textCurso,  gridCurso, gridLink,
    } = classes;
    return (

        <div style={{ backgroundColor: '#F8F8FA' }}>
            <Header />
            <div style={{paddingBottom:'8px'}}>
                <Grid container className={newGrid} >
                <Grid item md={3} className={menu}>
                       <MenuPerfil />
                      
                    </Grid>
                    <Grid item md={9} xl={12} className={perfil}>
                        <Grid className={page}>
                            <Grid item md={12}>
                                <Typography className={titulo}>ACESSO AOS CURSOS</Typography>
                            </Grid>

                        </Grid>

                        <Grid className={page2}>
                            <Grid item md={12}>
                                <Image
                                    className={imagemCurso}
                                    src={imgAP}
                                    alt="Imagem Curso"
                                />
                            </Grid>

                        </Grid>
                        <Grid className={gridCurso}>
                            <Grid item md={12}>
                               <Typography className={textCurso}>Os cursos são fornecidos pelo Grupo Pró-Fono, 
                                   parceira da Minha Fono®, clique no botão abaixo e tenha acesso a mais de XX 
                                   cursos profissionalizantes! </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12} className={bntCurso}>
                               <Link to="/" className={gridLink}>ACESSAR MEUS CURSOS</Link>
                            </Grid>


                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
}
