import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Grid, Typography, Link } from '@material-ui/core';

const Rule = ({ color }) => (
	<hr
		style={{
			borderColor: color,
			height: '1px',
			marginBottom: '40px',
		}}
	/>
);

const useStyles = makeStyles({
	footer: {
		'@media (max-width: 500px)': {
			margin: '50px 20px 0 20px',
		},
		backgroundColor: '#F8F8FA',
		margin: '5% 8% 0 8%',
		paddingBottom: '50px',
		textAlign: 'left',
	},

	p: {
		color: '#000',
		fontFamily: 'Montserrat',
		fontSize: '16px',
		fontWeight: 300,
		textDecoration: 'none'
	},

	title: {
		fontFamily: 'Montserrat',
		fontSize: '23px',
		fontWeight: 700,
		marginBottom: 10


	},

	gridFooter: {
		display: 'flex',
		'@media (max-width: 500px)': {
			display: 'block'

		},
	},
	icons: {
		color: '#000',
		marginRight: 20,
		textDecoration: 'none',
		fontSize: 45,
		'&:hover': {
			color: '#54B5CF'
		},
	},
	linkFooter: {
		color: '#000',
		fontFamily: 'Montserrat',
		fontSize: 20,
		fontWeight: 400,
		textDecoration: 'none',
		lineHeight: '50px',
		'&:hover': {
			color: '#54B5CF',
			textDecoration: 'none',
		},

	},
});

const Footer = (props) => {
	const classes = useStyles();
	return (
		<Grid className={classes.footer}>
			<Rule />
			<Grid className={classes.gridFooter}>
				<Grid item md={2}> 
					<Typography className={classes.title}>Pró-Fono</Typography>
					<Link href="https://minhafono.com.br/sobre" className={classes.linkFooter}>Sobre</Link><br />
					<Link href="https://profono.com.br/loja/" target="_blank" className={classes.linkFooter}>Produtos</Link><br />
					<Link href="https://profono.com.br/blog/" target="_blank" className={classes.linkFooter}> Blog</Link>

				</Grid> 

				<Grid item md={3}>
					<Typography className={classes.title}>Sou Especialista</Typography>
					<Link href="/admin/dashboard/registro" className={classes.linkFooter}>Quero me cadastrar</Link>

				</Grid>

				<Grid item md={3}>
					<Typography className={classes.title}>Especialidade</Typography>
					<Link href="/profissionais-cards?category=fonoaudiologia" className={classes.linkFooter}>Fonoaudiologia</Link><br />
					<Link href="/profissionais-cards?category=psicologia" className={classes.linkFooter}>Psicologia</Link><br />
					<Link href="/profissionais-cards?category=pedagogia" className={classes.linkFooter}>Pedagogia</Link><br />
					<Link href="/profissionais-cards?category=fisioterapia" className={classes.linkFooter}>Fisioterapia</Link><br />
				</Grid>
				<Grid >
					<Typography className={classes.title}>Redes Sociais</Typography>
					<Link href="https://api.whatsapp.com/send?phone=5511991755257&text=" target="_blank"><WhatsAppIcon className={classes.icons} /></Link>
					<Link href="https://www.facebook.com/profono/" target="_blank"><FacebookIcon className={classes.icons} /></Link>
					<Link href="https://www.instagram.com/pro_fono/" target="_blank" ><InstagramIcon className={classes.icons} /> </Link>
					<Link href="https://www.youtube.com/user/Profono" target="_blank" ><YouTubeIcon className={classes.icons} /> </Link>
					
					<Typography style={{ margin: '20px 0 0 0', fontSize:'15px' }}>© Copyright 2022 minhafono.com.br - Todos os direitos reservados</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
