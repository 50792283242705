import { api } from './backend';

//export const update = (data) => api.patch('/front/professionals/', data);
export const updateProfile = (data) => api.patch('front/secured/profile', data);

export const updatePhoto = (id, data) => api.put(`front/professionals/${id}`, data);

export const get = () => api.get('/front/professionals/');

export const getById = (id) => api.get(`/front/professionals/${id}`);

export const getProfile = () => api.get('/front/secured/profile');

export const list =(page, limit, filters, supervisor)=> api.get('/front/professionals',{ params: { page, limit, ...filters, supervisor }}); 

export const search =(page, limit, filters)=> api.get('/front/professionals/search',{ params: { page, limit, ...filters }});

export const addClinic = (id)=> api.post('/front/clinic-addresses',{id})  

export const removeClinic = (id)=> api.delete(`/front/clinic-addresses/${id}`)

export const addProfessionalAttendenceMethods = (data)=> api.post('/front/professional-attendence-methods', data)

//export const removeProfessionalAttendenceMethods = (id)=> api.delete(`/front/professional-attendence-methods/${id}`)

export const getProfessionalAttendenceMethods = (id) => api.get(`/front/professional-attendence-methods/${id}`);

export const getAttendenceMethods = (id) => api.get(`/front/attendence-methods`);

export const postProfessionalAttendenceMethods = (data) => api.post(`/front/professional-attendence-methods`, data);

export const postProfessionalSpecialities = (data) => api.post(`/front/professional-specialities`, data);

export const getProfessionalSpecialities = (id) => api.get(`/front/professional-specialities/${id}`); 

export const getCities = () => api.get(`/front/professionals/cities`); 

export const searchCity = (data) => api.get(`/front/professionals/search-city?search=${data}`); 

export const getByMail = (id) => api.get(`/backoffice/professionals/email/${id}`);
export const sendMail = (data) => api.post(`/backoffice/emails/send`, data);
export const changePassword = (id, data) => api.put(`/backoffice/professionals/${id}`, data);

export const postGenerateToken = () => api.post(`/front/professionals/generate-token`);

export const postCreateLink= (data) => api.post(`/front/professionals/create-link`, data);