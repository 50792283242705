import * as types from '../../constants';
import { create } from '../../services/api/AuthService';

export function userRegister(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_REGISTER_REQUEST });

    return create(credentials)
      .then((response) => { 
         dispatch({
          type: types.AUTH_REGISTER_REQUEST, 
          email: response.email,
          name: response.name,
          password: response.password,
          confirmPassword: response.confirmPassword,
          crfa: response.crfa,
        });  
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}
 
