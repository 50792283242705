import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutli from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles({
  formControl: {
    color: "#C4C4C4",
    display: "flex",
  },
  icone: {
    color: "#C4C4C4",
    margin: "0 0 -30px 0",
  },
});

const horarioAtendimento = [
  {
    id: "1",
    name: "Manhã",
  },
  {
    id: "2",
    name: "Tarde",
  },
  {
    id: "3",
    name: "Noite",
  },
];
export default function CheckboxAttendHours(props) { 
  const classes = useStyles();
  const [Checked, setChecked] = useState([]);
  const handleToggle = (value) => {
    const currentIndex = Checked.indexOf(value);
    const newChecked = [...Checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    props?.onChange?.(newChecked);
  };

  return (
    <>
      {horarioAtendimento.map((value, index) => (
        <div key={index}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Checked.indexOf(value.name) === -1 ? false : true}
                    onChange={() => handleToggle(value.name)}
                    icon={<CheckBoxOutli />}
                    className={classes.icone}
                    color="default"
                  />
                }
                selected={Checked.includes(value.id)}
                labelPlacement="start"
              />
              {value.name}
            </FormGroup>
          </FormControl>
        </div>
      ))}
    </>
  );
}
