import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';

import useStyles from '../../../components/styles/profissional.styles';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import MenuPerfil from '../../../components/menuPerfil';
import { useMemo, useState, useEffect, Fragment, useRef } from "react";

import * as PlansServices from '../../../services/api/PlansServices';
import * as PaymentServices from '../../../services/api/PaymentService';
import * as ProfessionalServices from '../../../services/api/ProfessionalsServices';

import {
    Container, Row, Col

} from 'react-bootstrap'

import moment from 'moment';
import Swal from 'sweetalert2';

export default function Perfil() {
    const classes = useStyles();
    const {
        newGrid, menu, perfil, page,  pageGeral, subTitulo, rowGrid, textBenef, textPlanoData, tituloperfil,
    } = classes;
    const [plan, setPlan] = useState(null);
 
    useEffect(() => {
        (async () => {
            const resp = await PlansServices.get(); 
            setPlan(resp.data[0]);     
        })();
      }, []);

      

      const handlerSubmitPlan = async() =>{
        
                let link;

                const user = await ProfessionalServices.getProfile(); 
    
                const dataToken = await ProfessionalServices.postGenerateToken();
    
                if(dataToken.data.access_token){
                    let obj = {
                        "token": dataToken.data.access_token,
                        "type": "Recurrent",
                        "name": "Assinatura minha fono",
                        "description": `plan=021f5207-9579-43b9-af00-90a0a31bd2es&id=${user.data.id}`,
                        "showDescription": true,
                        "price": "1490",  
                        "maxNumberOfInstallments": "1",
                        "quantity": 1, 
                        "shipping": {
                          "type": "WithoutShipping",
                          "name": "teste",
                          "price": "1000000000"
                        },
                        "recurrent": {
                          "interval": "Monthly"
                        }
                      }
                    const create = await ProfessionalServices.postCreateLink(obj); 
                    console.log(create) 
                    link = create
                }
                 
        Swal.fire({
            title: 'Acesse o link de pagamento abaixo',
            icon: 'success',
            html: 
              `<a href=${link.data.shortUrl}>${link.data.shortUrl}</a> `,
            showCloseButton: false,
            showCancelButton: true,
            showConfirmButton: false,
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Great!',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              'Cancelar', 
          }) 
    }

      const handlerSubmit = async() =>{
        Swal.fire({
            title: 'Assinatura Minha Fono',
            text: "GARANTA SEUS BENEFÍCIOS E FAÇA PARTE DA EQUIPE MINHA FONO®!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, assinar!',
            cancelButtonText: 'Não',
          }).then((result)   => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Sucesso!',
                text: 'Aproveite a sua assinatura.',
                icon:'success',
                confirmButtonColor: '#3085d6',
                willClose: () => {
                    window.location.reload();
                }
                })
              const body = {
                plans_id: '021f5207-9579-43b9-af00-90a0a31bd2er',
                isActive: true
                }
               PlansServices.post(body); 
            }
          }) 
    }

    const handlerSubmitCancel = async(id) =>{
        Swal.fire({
            title: 'Alerta!',
            text: "Deseja realmente cancelar sua assinatura?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result)   => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Sucesso!',
                text: 'Assinatura cancelada.',
                icon:'success',
                confirmButtonColor: '#3085d6',
                willClose: () => {
                    window.location.reload();
                }
                })
               PlansServices.remove(id); 
            }
          }) 
    }

    return (
        <div style={{ backgroundColor: '#F8F8FA' }}>
            <Header />
            <div>
                <Grid className={newGrid} >
                    <Grid item md={3} className={menu}>
                        <MenuPerfil />

                    </Grid>
                    <Grid item md={9} className={perfil}>
                        <Grid className={page}>
                            <Grid item md={9} xs={7}>
                                <Typography className={tituloperfil}>ASSINATURA</Typography>
                            </Grid>
                        </Grid>

                        <Grid className={ pageGeral}>
                        <Row> 
                </Row> 
                {plan?.plans_id !== null &&  plan? 
                
                    <><Grid item md={12}>
                                    <Typography className={subTitulo}>PLANO {plan?.plans?.title.toUpperCase()}</Typography>
                                    <Grid className={rowGrid}>ACESSO DE {plan?.plans?.days} DIAS</Grid>
                                    <Row className={rowGrid}><Grid item md={4}>INÍCIO DO PLANO</Grid>
                                        <Grid item md={6} className={textPlanoData}>{moment(plan?.date_start).format('DD/MM/YYYY')}</Grid></Row>
                                    <Row className={rowGrid}><Grid item md={4}>TÉRMINO DO PLANO</Grid>
                                        <Grid item md={6} className={textPlanoData}>{moment(plan?.date_due).format('DD/MM/YYYY')}</Grid></Row>

                                </Grid><Grid item md={12} style={{ marginTop: 50 }}>
                                        <Typography className={subTitulo}>BENEFÍCIOS </Typography>
                                        <Grid className={textBenef}>
                                            <li>Dilvulgação do perfil profissional na plataforma Minha Fono®</li>
                                            <li>Acesso aos cursos fornecidos pelo parceiro Pró-Fono.</li>
                                            <li>Acesso aos aplicativos forecidos pelo parceiro Pró-Fono.</li>
                                        </Grid>

                                    </Grid>
                                    <Link className={classes.botaoCancel} onClick={()=>{handlerSubmitCancel(plan?.id)}}>Cancelar</Link>{''}
                                    </> 
                :  
            <><Row className={classes.colDois}>
                    <Col sm={6} className={classes.linha} >
 
       
                        <p className={classes.p3}>Gratuito</p>
                        <p className={classes.p3}>por tempo indeterminado </p>
                        


                    </Col>

                    <Col sm={6} className={classes.colTres} >

                        <Grid style={{ display: 'flex' }}>
                            <Grid item sm={12}><Typography className={classes.p6}> GARANTA SEUS BENEFÍCIOS<br />E FAÇA PARTE DA EQUIPE<br /> MINHA FONO<span className={classes.r}>®</span>!</Typography>
                            </Grid>
                        </Grid>

                        <Link className={classes.botao} onClick={()=>{handlerSubmit()}}>ASSINAR</Link>{''}
                    </Col>
                </Row>  
                        </>} 
                        </Grid>{/* 
                      <Link className={classes.botao} onClick={()=>{handlerSubmitPlan()}}>ASSINAR</Link>{''} */}

                    </Grid>

                </Grid>
            </div>
            <Footer />
        </div>

    );
}


