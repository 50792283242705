import React from 'react';
import {
    Container, Row, Col, Button

} from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import Image from 'react-bootstrap/Image';

import imgAP from '../../assets/img/imagemAP.jpg';

import Header from '../../components/header';
import Footer from '../../components/footer';




const useStyles = makeStyles({
    botao: {
        backgroundColor: '#54B5CF',
        fontFamily:'Roboto',
        fontWeight:400,
        border: 'none', borderRadius: '30px',
        display: 'flex',
        fontSize: '31px',
        justifyContent: 'center',
        margin: '0 auto',
        width: '35%',
        "@media (max-width: 500px)": {
            backgroundColor: '#54B5CF',
            fontSize: '15px',
            width: '80%',
        }
    },

    colUm: {
        padding: '50px 50px 0px 50px',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            padding: '0px 20px 0px 20px',
        }
    },

    p: {
        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '68px',
        fontWeight: 'bold',
        padding: '50px 0px 0px 0px',
        textAlign: 'center',
        "@media (max-width: 500px)": {
            fontSize: '20px'
        }
    },

    p2: {

        color: '#54B5CF',
        fontFamily: 'Montserrat',
        fontSize: '37px',
        fontWeight: 'bold',
        padding: '20px 25% 0px 25%',
        textAlign: 'center',
    },
    p3: {
        color: '#666666',
        fontFamily: 'Roboto',
        fontSize: '34px',
        fontWeight: 'lighter',
        padding: '20px 16% 5% 15%',
        textAlign: 'justify',

    },
    imagem: {
        height: '470px',
        width: '100%',
        "@media (max-width: 500px)": {
            width: '100%',
            height: '200px',
        }
    }

});

export default function Obrigado() {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: '#F8F8FA' }}>
            <Header />
            <Container fluid >
                <Row>
                    <Col sm={12} style={{ padding: '0px' }}>
                        <Image
                            className={classes.imagem}
                            src={imgAP}
                            alt="First slide"
                        />
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col sm={12}><h1 className={classes.p}>OBRIGADO!</h1>
                        <p className={classes.p2}>A sua solicitação para o grupo <br /> Minha Fono foi enviada!</p>
                        <p className={classes.p3}>Seu cadastro será avaliado e dentro de alguns dias seu login
                        e senha serão enviados para o seu email!</p>
                    </Col>
                    <Button className={classes.botao} href="/">RETORNAR PARA A HOME</Button>{''}
                </Row>
            </Container>

            <Footer />
        </div>
    )
}