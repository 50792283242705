import * as types from '../../constants';

export default function reducer(state = {}, actions) {

  switch (actions.type) { 
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
        },
      };
      case types.AUTH_REGISTER_REQUEST:
      return {
        ...state,
        user: { 
          email: actions.email,
          name: actions.name,
          password: actions.password,
          confirmPassword: actions.confirmPassword,
          crfa: actions.crfa,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
