import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutli from "@material-ui/icons/CheckBoxOutlineBlank";
import * as SpecialitiesServices from "../../services/api/SpecialitiesServices";

const useStyles = makeStyles({
  formControl: {
    color: "#C4C4C4",
    display: "flex",
  },
  icone: {
    color: "#C4C4C4",
    margin: "0 0 -30px 0",
  },
});

export default function CheckboxFilter(props) {
  const classes = useStyles();
  const [specialities, setSpecialities] = useState([]); 
 
  React.useEffect(() => {
    (async () => {
      const resp = await SpecialitiesServices.get(); 
      setSpecialities(resp.data);
    })();
  }, []);

  const [values, setValues] = useState([]);
 
  const handleToggle = (value) => { 
    const currentIndex = values.indexOf(value);
    const newValues = [...values];

    if (currentIndex === -1) {
      newValues.push(value);
    } else {
      newValues.splice(currentIndex, 1);
    } 
    setValues(newValues);
    props?.onChange?.(newValues); 
  };
  
  
  return (
    <>
      {specialities?.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
              }).map((value, index) => ( 
        <div key={index}>  
          <FormControl component="fieldset" className={classes.formControl}>
            {value.attendenceMethod?.name === 'PRESENCIAL' &&  
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.includes(value.name)}
                    onChange={() => handleToggle(value.name)}
                    icon={<CheckBoxOutli />}
                    className={classes.icone}
                    color="default"
                  />
                }
                selected={values.includes(value.id)}
                labelPlacement="start"
              />
              {value.name}
            </FormGroup>} 
          </FormControl>
        </div>
      ))}
    </>
  );
}
