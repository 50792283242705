import * as types from '../../constants';
import { signIn, signOut } from '../../services/api/AuthService';
 
export function userSignIn(credentials) {

  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return signIn(credentials)
      .then((response) => { 
        console.log(response) 
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          id: response.id,
          email: credentials.email,
          name: credentials.name,
        }); 
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function userSignOut() {
  return async (dispatch) => {
    await signOut();

    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}
