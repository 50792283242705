import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link } from "@material-ui/core";
import { Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";

import imgAp from "../../assets/img/imgLogin.jpg";

import Header from "../../components/header";
import Footer from "../../components/footer";

import * as ProfessionalsServices from "../../services/api/ProfessionalsServices";
import * as CommentsServices from "../../services/api/CommentsServices";

const useStyles = makeStyles((theme) => ({
  busca: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  bnt: {
    backgroundColor: "#7ECDE2",
    border: 0,
    borderRadius: 5,
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    margin: "5px 0 5px 0",
    width: "50%",
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
  botao: {
    backgroundColor: "#54B5CF",
    border: 0,
    borderRadius: "10px",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: 900,
    textAlign: "center",
    padding: "5px 20px 5px 20px",
    "&:hover": {
      backgroundColor: "#0facc4",
      color: "#fff",
    },

    "@media (max-width: 500px)": {
      backgroundColor: "#54B5CF",
      fontSize: "13px",
      width: "100%",
      height: "30px",
      padding: 0,
    },
  },
  botaoFrom: {
    marginTop: "40px",
    textAlign: "center",
  },

  colDois: {
    display: "flex",
    "@media (max-width: 500px)": {
      display: "grid",
    },
  },
  body: {
    backgroundColor: "#fff",
    borderRadius: "23px",
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    margin: "30px 0 30px 0",
    padding: "30px 30px 50px 30px",
    "@media (max-width: 500px)": {
      width: "95%",
      margin: "10px 0 0 10px",
    },
  },
  form: {
    marginTop: theme.spacing(3),
    width: "100%", // Fix IE 11 issue.
  },

  iconButton: {
    backgroundColor: "#54B5CF",
    border: 0,
    borderRadius: "0 30px 30px 0",
    color: "#fff",
    height: 50,
    width: "15%",
    "&:hover": {
      backgroundColor: "#0facc4",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
  icone: {
    color: "#C4C4C4",
    margin: "0 0 -30px 0",
  },
  imagem: {
    objectFit: 'cover!important',
    borderRadius: "50%",
    width: "250px",
    height: "250px",
    "@media (max-width: 500px)": {
      width: "175px",
      height: "175px",
      marginBottom: "0 0px 0 30px",
    },
  },
  link: {
    color: "#54B5CF",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#0facc4",
    },
  },

  p: {
    color: "#fff",
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
    margin: "0 0 0 20px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    "@media (max-width: 500px)": {
      fontSize: "15px",
    },
  },

  root: {
    backgroundColor: "#fff",
    border: 0,
    borderRadius: 30,
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    display: "flex",
    margin: "50px 10% 50px 10%",
    padding: "0 0 0 35px",
    width: "100%",
  },
  span: {
    color: "#54B5CF",
  },

  submit: {
    backgroundColor: "#54B5CF",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: 900,
    borderRadius: "10px",
    padding: "5px 20px 5px 20px",
    margin: theme.spacing(2, 0, 1),
    "&:hover": {
      backgroundColor: "#0facc4",
      color: "#fff",
    },
  },

  typography: {
    borderColor: "#54B5CF",
    borderStyle: "Solid",
    borderWidth: "0 0  3px 0",
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontSize: 25,
    fontWeight: 700,
    padding: "0 0 20px 0",
    margin: "0 0 30px",
  },
  text: {
    color: "#55B5CF",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 900,
    padding: "10px 0 10px 0",
    "@media (max-width: 500px)": {
      margin: 0,
    },
  },
  textSeg: {
    display: "flex",
    flexDirection: "column",
    color: "#C4C4C4",
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: 400,
    padding: "0 0 20px 0",
  },
  textTer: {
    whiteSpace: "pre-wrap",
    flexDirection: "column",
    color: "#C4C4C4",
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: 400,
    padding: "0 0 20px 0",
  },
  textQua: {
    color: "#C4C4C4",
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: 500,
    padding: "0 0 20px 0",
  },
  textQui: {
    borderColor: "#54B5CF",
    borderStyle: "Solid",
    borderWidth: "0 0 0 3px",
    padding: "5px 0 0 40px",
    margin: "0 0 0 -40px",
    "@media (max-width: 500px)": {
      borderWidth: "0",
      margin: "0 0 0 -30px",
    },
  },
  typographyTitle: {
    borderWidth: "0 0  2.5px 0",
    borderStyle: "Solid",
    borderColor: "#54B5CF",
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: 900,
    padding: "0 0 20px 0",
    margin: "50px 30px 0 50px",
    "@media (max-width: 500px)": {
      margin: "30px 0 0 0",
      fontSize: "20px",
    },
  },
  typographyForm: {
    color: "#7ECDE2",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: 900,
    marginBottom: 20,
  },

  voltar: {
    color: "#54B5CF",
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: "700",
    marginTop: 30,
  },
  whats: {
    marginTop: "15px",
    backgroundColor: "#7AD06D",
    border: 0,
    borderRadius: 30,
    boxShadow: " 0 0 20px rgba(0, 0, 0, 0.1)",
    display: "flex",
    padding: 10,
    margin: "-50px 0 0 0",
    width: "85%",
    "@media (max-width: 500px)": {
      width: "100%",
      margin: "0 0 0 -0px",
    },
  },
  main: {
    alignItems: "center",
    display: "flex",
    width: "1200px",
  },
  search: {
    alignItems: "center",
    display: "flex",
    width: "1200px",
    "@media (max-width: 500px)": {
      width: "100%",
      margin: "0 0 0 -0px",
    },
  },
  bntService: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#0facc4",
    color: "#666666",
    width: "90%",
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Roboto",
    borderRadius: "4px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  title: {
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 900,
    color: "#55B5CF",
    textAlign: "center",
    margin: "10px 0 10px 0",
    "@media (max-width: 500px)": {
      fontSize: "15px",
    },
  },
  linkContato: {
    textDecoration: "none",
    color: "#666666",
  },
  segundaColuna: {
    padding: "0 0 0 30px",
    "@media (max-width: 500px)": {
      padding: 0,
    },
  },
  textcomments: {
    margin: "20px 0px 0 50px",
    "@media (max-width: 500px)": {
      margin: "10px 0 0 0",
    },
  },
  gridcomments: {
    margin: "-20px 0px 0 -30px",
    "@media (max-width: 500px)": {
      margin: "-20px 10px 0 0",
    },
  },
  mainFrom: {
    width: "60%",
    padding: "50px 0 0 0",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  comment: {
    margin: "-20px 0px 0 -30px",
    textAlign: "left",
    "@media (max-width: 500px)": {
      margin: "-10px 0px 0 -10px",
    },
  },
  input: {
    " &:focus": {
      boxShadow: "0 0 0px #fff",
      borderColor: "#0facc4",
    },
  },
  start: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

export default function ProfissionalDetalhes() {
  const [rating, setRating] = useState(0); 

  const classes = useStyles(); 

  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  const { id } = useParams();
  const [professional, setprofessional] = React.useState([]);
  useEffect(() => {
    (async () => {
      const professional = await ProfessionalsServices.getById(id);
      setprofessional(professional.data);
    })();
  }, [id]);

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: name,
      rate: Number(rating),
      comment: comment,
      professionalId: id,
    };

    await CommentsServices.post(data).then(
      (success) => {
        setStatus({
          type: "success",
          mensagem: "Obrigada pelo seu comentário",
        });

        setName("");
        setComment("");
        setRating(0);
      },
      (error) => {
        setStatus({
          type: "error",
          mensagem:
            "Erro: Seu comentário não foi enviado, você precisa preencher todos os campos",
        });
      }
    );

    const resp = await CommentsServices.getById(id);
    setComments(resp.data);
  };

  // eslint-disable-next-line no-unused-vars
  const [comments, setComments] = useState([]);
  useEffect(() => {
    (async () => {
      const resp = await CommentsServices.getById(id);
      setComments(resp.data);
    })();
  }, [id]);

  return (
    <div style={{ backgroundColor: "#F8F8FA" }}>
      <Header />
      <Container component="main">
        <Typography className={classes.voltar}>
          <KeyboardBackspaceIcon />{" "}
          <Link href="/profissionais-cards" className={classes.link}>
            Voltar
          </Link>
        </Typography>
      </Container>

      <Container>
        <Row>
          <Grid className={classes.body}>
            <Grid className={classes.colDois}>
              <Grid item md={3} style={{ textAlign: "center" }}>
                {professional.picture ? (
                  <Image
                    className={classes.imagem}
                    src={professional.picture}
                    alt="Imagem Curso"
                  />
                ) : (
                  <Image
                    className={classes.imagem}
                    src={imgAp}
                    alt="Imagem Curso"
                  />
                )}

                <Typography className={classes.title}>Atendimento</Typography>
                {professional?.attendances?.map((attendance) => (
                  <>
                    <Grid key={attendance.id}>
                      <Typography className={classes.bntService}>
                        {attendance.online}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography className={classes.bntService}>
                        {attendance.inPerson}
                      </Typography>
                    </Grid>
                  </>
                ))}
                <Typography className={classes.title}>Contato</Typography>
                <Grid>
                  <Link href="/" className={classes.linkContato}>
                    <WhatsAppIcon
                      desabled="true"
                      style={{ color: "#25d366", margin: "0 10px 0 0" }}
                    />
                    {professional?.whatsapp}
                  </Link>
                </Grid>
                <Grid>
                  <Link to="/" className={classes.linkContato}>
                    <PhoneIcon
                      desabled="true"
                      style={{ color: "#34b7f1", margin: "0 10px 0 0" }}
                    />
                    {professional?.phone}
                  </Link>
                </Grid>
                <Grid>
                  <Link to="/" className={classes.linkContato}>
                    <MailOutlineIcon
                      desabled="true"
                      style={{ color: "#34b7f1", margin: "0 10px 0 0" }}
                    />
                    {professional?.email}
                  </Link>
                </Grid>
              </Grid>
              <Grid item md={9} className={classes.segundaColuna}>
                <Typography className={classes.text}>
                  {professional?.name}
                </Typography>
                <Typography className={classes.textSeg}>
                  <span> {professional?.formation}</span>
                  <span> {professional?.title}</span>
                  <span>Número do Conselho de Classe: {professional?.crfa}</span>
                </Typography>
                <Typography className={classes.text}>
                  Convênios
                  <Row>
                  {professional.professionalInsurances?.map((home) => (
                    <Grid item md={3} style={{ display: "flex" }}>
                      <Typography className={classes.textSeg}>
                        {home.insurance?.name}
                      </Typography>
                    </Grid>
                  ))}
                  </Row>
                </Typography>
                <Typography className={classes.text}>
                  Informações Adicionais
                </Typography>
                <Typography
                  className={classes.textTer}
                  style={{ whiteSpace: "pre-wrap" }}
                ></Typography>
                {professional.description?.length <= 120 && (
                  <p style={{ flexShrink: 1 }} className={classes.textTer}>{professional.description}</p>
                )}
                {professional.description?.length > 120 && (
                  <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} className={classes.textTer}>{professional.description} </p>
                )}
                <br />
                <Typography className={classes.text}>ATENDIMENTO</Typography>
                <Row>
                  {professional.professionalAttendenceMethods?.map((home) => (
                    <Grid item md={6} style={{ display: "flex" }}>
                      <Typography className={classes.textSeg}>
                        {home.attendenceMethod.name}
                      </Typography>
                    </Grid>
                  ))}
                </Row>
                {professional?.clinicAddresses?.map((clinicAddress) => (
                  <Typography className={classes.textQua}>
                    <Typography className={classes.text}>
                      Informações do Consultório
                    </Typography> 
                    <br />
                    <span>Nome: {clinicAddress?.clinic_name}</span> 
                    <br />
                    <span>Telefone: {clinicAddress?.clinic_contact}</span> 
                    <br />
                    <span>Endereço: {clinicAddress?.street} - {clinicAddress?.number}, {clinicAddress?.complement}</span>
                    <br /> 
                    <span>Bairro: {clinicAddress?.district}</span>
                    <br /> 
                    <span>
                      Cidade: {clinicAddress?.city}
                    </span>
                    <br /> 
                    <span>
                      Estado: {clinicAddress.state}
                    </span>
                    <br />
                    <span>CEP: {clinicAddress.zipcode}</span>
                  </Typography>
                ))}
                <Row>
                  {/* <Grid item md={12} >
                                        <MapaGoogle />
                                    </Grid> */}
                  <Grid className={classes.whats}>
                    <WhatsAppIcon style={{ color: "#fff" }} />
                    <Link
                      className={classes.p}
                      href={`https://api.whatsapp.com/send?phone=${professional?.whatsapp?.replace(
                        /\D/g,
                        ""
                      )}&text=`}
                      target="_blank"
                    >
                      FALE COM O PROFISSIONAL E AGENDE SEU HORÁRIO VIA WHATSAPP!
                    </Link>
                  </Grid>
                </Row>
              </Grid>
            </Grid>

            {/* <Typography className={classes.typographyTitle}>
              Comentários de Pacientes
            </Typography>
            <Row className={classes.textcomments}>
              <Grid className={classes.botaoFrom}>
                <NoSsr defer={state.defer}>
                  <Grid container spacing={4}>
                    {comments.items &&
                      comments.items.slice(0, 2).map((data) => (
                        <Grid item key={data.id} xs={12} sm={6} md={6}>
                          <Col style={{ display: "flex" }}>
                            <Grid item sm={2}>
                              <Avatar>{data.name.charAt(0)}</Avatar>
                            </Grid>
                            <Grid item sm={12} className={classes.comment}>
                              <CardContent className={classes.cardContent}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {data.name} teste3213231
                                </Typography>
                                <Grid item md={6} className={classes.start}>
                                  <Ratings
                                    name="hover-feedback"
                                    value={data.rate}
                                    disabled
                                    precision={1}
                                  />
                                </Grid>
                                <Typography style={{ marginTop: "15px" }}>
                                  {data.comment}
                                </Typography>
                              </CardContent>
                            </Grid>
                          </Col>
                        </Grid>
                      ))}
                  </Grid>
                </NoSsr>
                {state.open ? (
                  <NoSsr defer={state.defer}>
                    <Grid container spacing={4}>
                      {comments.items &&
                        comments.items.slice(2).map((data) => (
                          <Grid item key={data.id} xs={12} sm={6} md={6}>
                            <Col style={{ display: "flex" }}>
                              <Grid item sm={2}>
                                <Avatar>{data.name.charAt(0)}</Avatar>
                              </Grid>
                              <Grid item sm={12} className={classes.comment}>
                                <CardContent className={classes.cardContent}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                  >
                                    {data.name}
                                  </Typography>
                                  <Grid item md={6} className={classes.start}>
                                    <Ratings
                                      name="hover-feedback"
                                      value={data.rate}
                                      disabled
                                      precision={1}
                                    />
                                  </Grid>
                                  <Typography style={{ marginTop: "15px" }}>
                                    {data.comment}
                                  </Typography>
                                </CardContent>
                              </Grid>
                            </Col>
                          </Grid>
                        ))}
                    </Grid>
                  </NoSsr>
                ) : null}

                <button
                  type="button"
                  variant="contained"
                  className={classes.botao}
                  onClick={() =>
                    setState({
                      open: !state.open,
                      defer: false,
                    })
                  }
                >
                  {"Mostrar o Restante dos Comentários"}
                </button>
                <br />
              </Grid>
              <Container component="main" className={classes.mainFrom}>
                <CssBaseline />
                <Typography className={classes.typographyForm}>
                  Você é paciente desse profissional?Deixe aqui a sua avaliação!
                </Typography>
                {status.type === "success" ? (
                  <p style={{ color: "green" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}
                {status.type === "error" ? (
                  <p style={{ color: "#ff0000" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nome"
                      className={classes.input}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Typography
                    className={classes.typographyForm}
                    style={{ fontSize: "14px" }}
                  >
                    Clique duas vezes para dar nota ao profissional
                  </Typography>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Rating onClick={handleRating} ratingValue={rating} />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Escreva aqui avaliação do profissional"
                      className={classes.input}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Form.Group>
                  <Grid className={classes.botaoFrom}>
                    <Button
                      type="submit"
                      className={classes.submit}
                      onClick={handleSubmit}
                    >
                      SALVAR
                    </Button>
                  </Grid>
                </Form>
              </Container>
            </Row> */}
          </Grid>
        </Row>
      </Container>

      <Container component="main">
        <Typography className={classes.voltar}>
          <KeyboardBackspaceIcon />{" "}
          <Link href="/profissionais-cards" className={classes.link}>
            Voltar
          </Link>
        </Typography>
      </Container>
      <Footer />
    </div>
  );
}
